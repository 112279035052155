











































































import MNotificationVue from "@/mixins/MNotification.vue";
import { DataMenu } from "@/models/interface/user.interface";
import { userServices } from "@/services/user.services";
import { Component, Vue, Watch } from "vue-property-decorator";

export type Row = {
  id: string | null;
  idMenu: string | null;
  description: string | null;
  create: boolean;
  read: boolean;
  update: boolean;
  delete: boolean;
  createId: string | null;
  readId: string | null;
  updateId: string | null;
  deleteId: string | null;
  key: number;
  disabled: boolean;
};

const Props = Vue.extend({
  props: {
    propsDataSource: {
      type: Array as () => Row[],
      required: true,
    },
    activeTabPane: {
      type: String,
    },
  },
});

@Component({
  mixins: [MNotificationVue],
})
export default class TableRole extends Props {
  dataSource: Row[] = [];
  selectedRowKeys: number[] = [];
  deleteAddPrivilegeIds: string[] = [];
  columnsTable = [
    {
      title: this.$t("lbl_menu"),
      dataIndex: "idMenu",
      key: "idMenu",
      width: 250,
      scopedSlots: { customRender: "selectMenu" },
    },
    {
      title: this.$t("lbl_description"),
      dataIndex: "description",
      key: "description",
      width: 250,
    },
    {
      title: this.$t("lbl_create"),
      dataIndex: "create",
      key: "create",
      width: 150,
      scopedSlots: { customRender: "create" },
      align: "center",
    },
    {
      title: this.$t("lbl_read"),
      dataIndex: "read",
      key: "read",
      width: 150,
      scopedSlots: { customRender: "read" },
      align: "center",
    },
    {
      title: this.$t("lbl_edit"),
      dataIndex: "update",
      key: "update",
      width: 150,
      scopedSlots: { customRender: "update" },
      align: "center",
    },
    {
      title: this.$t("lbl_delete"),
      dataIndex: "delete",
      key: "delete",
      width: 150,
      scopedSlots: { customRender: "delete" },
      align: "center",
    },
  ];

  @Watch("propsDataSource", {
    immediate: true,
  })
  onChangePropsDataSource(newValue: Row[]): void {
    this.dataSource = [...newValue];
  }

  onChangeMenu(value: string, index: number): void {
    // set menu dengan id / value
    const { dataSource } = this;
    this.dataSource[index] = {
      ...dataSource[index],
      idMenu: value,
    };
    this.emitChange("input");
  }

  setAutoFill(value: { meta: DataMenu }, key: number): void {
    const { meta } = value;
    // set description, create, read, update, delete
    this.dataSource[key] = {
      ...this.dataSource[key],
      description: meta.description ?? "",
      create: !!meta.create,
      read: !!meta.read,
      update: !!meta.update,
      delete: !!meta.delete,
      createId: meta.create,
      readId: meta.read,
      updateId: meta.update,
      deleteId: meta.delete,
    };
    this.emitChange("input");
  }

  async handleCheckBoxTable(
    value: boolean,
    key: number,
    col: string
  ): Promise<void> {
    this.dataSource[key][col] = value;
    if (value) {
      const res = await userServices.listOfMenus(
        { search: `secureId~${this.dataSource[key].idMenu}` },
        ""
      );
      const { data } = res;
      const [menu] = data as DataMenu[];
      this.dataSource[key][`${col}Id`] = menu[col];
    }
    this.emitChange("input");
  }

  emitChange(action: "addRow" | "deleteRow" | "input"): void {
    this.$emit("change:data-source", {
      action,
      dataSource: this.dataSource,
      deletedIds: this.deleteAddPrivilegeIds,
    });
    this.$forceUpdate(); // force re render ui
  }

  onSelectRow(selectedRowKeys: number[]): void {
    this.selectedRowKeys = selectedRowKeys;
  }

  handleAddRow(): void {
    const { dataSource } = this;
    const newRow: Row = {
      id: "",
      idMenu: null,
      description: null,
      create: false,
      read: false,
      update: false,
      delete: false,
      createId: null,
      readId: null,
      updateId: null,
      deleteId: null,
      key: dataSource.length,
      disabled: false,
    };
    this.dataSource = [...dataSource, newRow];
    this.emitChange("addRow");
  }

  showConfirmation(): void {
    if (!this.selectedRowKeys.length) {
      this.showNotifError("lbl_modal_delete_error_description");
      return;
    }
    this.$confirm({
      title: this.$t("lbl_modal_delete_title_confirm"),
      content: this.$t("lbl_modal_delete_info", {
        count: this.selectedRowKeys.length,
      }),
      onOk: this.handleDeleteRow,
    });
  }

  handleDeleteRow(): void {
    const { dataSource } = this;
    // delete row
    this.dataSource = dataSource.filter(data => {
      if (data.id && this.selectedRowKeys.includes(data.key)) {
        this.deleteAddPrivilegeIds.push(data.id);
      }
      return !this.selectedRowKeys.includes(data.key);
    });
    // kembalikan key sesuai urutan indexnya
    this.dataSource.forEach((data, index) => (data.key = index));
    this.selectedRowKeys = [];
    this.emitChange("deleteRow");
  }
}
